<template>
  <div class="channels bg-center text-start">
    <v-container>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12">
          <div class="relative top-2 left-{{ this.$vuetify.display.width >= 960 ? 20 : 12  }}">
            <p class="text-3xl font-bold text-white">Više od</p>
            <h1 class="text-5xl font-extrabold uppercase text-white"><span class="text-red-700">190</span> najboljih</h1>
            <p class="text-3xl font-bold text-white">TV kanala</p>
            <p v-if="biggerScreen" class="mt-4 font-semibold text-lg text-white">Uživaj u super ponudi informativnih, sportskih, filmskih, serijskih,<br>
             dječijih TV kanala i zabavi se uz omiljene radio stanice.</p>
             <p v-else class="mt-4 font-semibold text-base text-white">Uživaj u super ponudi informativnih, sportskih, filmskih, serijskih,<br>
              dječijih TV kanala i zabavi se uz omiljene radio stanice.</p>
          </div>
        </v-col>
        <v-col cols="6">
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div class="filter-buttons">
      <v-container class="text-center">
        <v-row justify="center" class="mt-2">
          <v-col class="text-center">
            <v-btn variant="tonal" color="white" @click="applyFilter('all')" class="mr-2 mb-2" :style="{background: clickedButton == null || clickedButton == 'all' ? '#b10d28' : ''}">
              <span v-if="biggerScreen">Svi kanali</span>
              <span v-else>Svi</span>
            </v-btn>
            <v-btn variant="tonal" color="white" @click="applyFilter('start')" class="mr-2 mb-2" :style="{backgroundColor: clickedButton == 'start' ? '#b10d28' : ''}">
             <span v-if="biggerScreen">MOVE TV start</span>
             <span v-else>MOVE start</span>
            </v-btn>
            <v-btn variant="tonal" color="white" @click="applyFilter('max')" class="mr-2 mb-2" :style="{backgroundColor: clickedButton == 'max' ? '#b10d28' : ''}">
              <span v-if="biggerScreen">MOVE TV</span>
              <span v-else>MOVE</span>
            </v-btn>
            <v-btn variant="tonal" color="white" @click="applyFilter('plus')" class="mr-2 mb-2" :style="{backgroundColor: clickedButton == 'plus' ? '#b10d28' : ''}">
              <span v-if="biggerScreen">MOVE TV plus</span>
              <span v-else>MOVE plus</span>
            </v-btn>
            <v-btn variant="tonal" color="white" @click="applyFilter('extra')" class="mr-2 mb-2" :style="{backgroundColor: clickedButton == 'extra' ? '#b10d28' : ''}">
              <span v-if="biggerScreen">MOVE TV extra</span>
              <span v-else>MOVE extra</span>
            </v-btn>
            <v-btn variant="tonal" color="white" @click="applyFilter('ino')" class="mr-2 mb-2" :style="{backgroundColor: clickedButton == 'ino' ? '#b10d28' : ''}">
              <span v-if="biggerScreen">Kanali u inostranstvu</span>
              <span v-else>U inostranstvu</span>
            </v-btn>
            <v-btn variant="tonal" color="white" @click="applyFilter('radio')" class="mb-2" :style="{backgroundColor: clickedButton == 'radio' ? '#b10d28' : ''}">Radio</v-btn>
          </v-col>
        </v-row>
      </v-container>
  </div>
  <div class="channels-list">
    <v-container>
      <v-row align="center" justify="center">
        <v-col xs="6" sm="6" md="4" lg="3" xl="2" align="center" v-for="card in filteredItems" :key="card.title">
          <v-card flat class="text-center bg-black px-7 rounded-lg d-flex flex-column" width="200" height="200">
            <div style="height:100px;">
              <v-img :src="require(`@/assets/images/tv_logos_new/${card.src}`)" cover width="300" height="300"></v-img>
            </div>
            <v-spacer></v-spacer>
              <div class="subheading text-white font-bold text-base text-center">
                <v-divider :thickness="3" opacity="100" color="white" class="pb-2"/>
                {{card.title}}
              </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <p v-if="this.activeFilter == 'ino'" class="text-white text-center mt-6">*Blicnet d.o.o. zadržava pravo da zatamni sadržaj za koji emiter nema prava prikazivanja izvan teritorije BiH.</p>
  </div>
</template>

<script>
import {ref, onBeforeMount, computed} from 'vue'

export default {
  setup(){
   
   const cards = ref(
    [
        {title: "BHRT HD", src: 'bht_6_dark.png', start: true, max: true},
        {title: "FTV HD", src: 'federalna-tv_6_dark.png', start: true, max: true},
        {title: "RTRS", src: 'rtrs_6_dark.png', start: true, max: true},
        {title: "Hayat HD", src: 'hayat-tv_6_dark.png', start: true, max: true},
        {title: "O Kanal", src: 'o-kanal_6_dark.png', start: true, max: true},
        {title: "FACE TV HD", src: 'face-tv_6_dark.png', start: true, max: true},
        {title: "Aljazeera HD", src: 'al-jazeera-balkans_6_dark.png', start: true, max: true},
        {title: "OBN", src: 'obn_6_dark.png', start: true, max: true},
        {title: "Hayat 2", src: 'hayat-2_6_dark.png', start: true, max: true},
        {title: "Superstar HD", src: 'superstar-tv-hd_6_dark.png', start: true, max: true},
        {title: "Pink BH", src: 'pink-bh_6_dark.png', start: true, max: true},
        {title: "RTV TK", src: 'rtv-tk_6_dark.png', start: true, max: true},
        {title: "BN HD", src: 'bntv_6_dark.png', start: true, max: true},
        {title: "RTV USK", src: 'rtv-usk_6_dark.png', start: true, max: true},
        {title: "RTV Cazin", src: 'rtv-cazin_6_dark.png', start: true, max: true, ino: true},
        {title: "Bir TV", src: 'bir-tv_6_dark.png', start:true, max: true},
        {title: "TV Slon", src: 'tv-slon_6_dark.png', start: true, max: true, ino: true},
        {title: "Izvorna TV HD", src: 'izvorna-tv_6_dark.png', start: true, max: true},
        {title: "Tatabrada TV", src: 'tatabrada_6_dark.png', start: true, max: true, ino: true},
        {title: "RTV Hit", src: 'rtv-hit_6_dark.png', start: true, max: true, ino: true},
        {title: "OTV Valentino", src: 'otv-valentino_6_dark.png', start: true, max: true},
        {title: "RTV Zenica", src: 'rtv-zenica_6_dark.png', start:true, max: true, ino: true},
        {title: "RTV BPK", src: 'rtv-bpk_6_dark.png', start: true, max: true, ino: true},
        {title: "Hayat Stil i život", src: 'hayat_stil_i_zivot.png', max: true},
        {title: "Una TV HD", src: 'una-tv_6_dark.png', start: true, max: true},
        {title: "ATV HD", src: 'atv_6_dark.png', start: true, max: true},
        {title: "K3 HD", src: 'k3_6_dark.png', start: true, max: true},
        {title: "Balkan Trip HD", src: 'balkan-trip-hd_6_dark.png', start: true, max: true},
        {title: "Kurir TV HD", src: 'kurir-tv_6_dark.png', max: true},
        {title: "Blic TV HD", src: 'blic-tv-hd_6_dark.png', start: true, max: true},
        {title: "ELTA 1 HD", src: 'elta-tv_6_dark.png', start: true, max: true, ino: true},
        {title: "RTV IS", src: 'rtv-is_6_dark.png', start: true, max: true, ino: true},
        {title: "Balkan TV", src: 'balkan-tv_6_dark.png', start: true, max: true},
        {title: "Herceg TV", src: 'herceg-tv_6_dark.png', start: true, max: true, ino: true},
        {title: "Arena TV", src: 'arena-tv_6_dark.png', start: true, max: true, ino: true},
        {title: "Simić TV HD", src: 'simic-tv_6_dark.png', start: true, max: true, ino: true},
        {title: "RTV Prijedor", src: 'rtv-prijedor_6_dark.png', start: true, max: true},
        {title: "RT KD", src: 'rt-kd_6_dark.png', start: true, max: true, ino: true},
        {title: "Patria", src: 'patria_6_dark.png', start: true, max: true, ino: true},
        {title: "TV Doboj", src: 'tv-doboj_6_dark.png', start: true, max: true, ino: true},
        {title: "TV Gradiška HD", src: 'gradiska-tv_6_dark.png', start: true, max: true},
        {title: "RTRS Plus", src: 'rtrs-plus_6_dark.png', start: true, max: true},
        {title: "BN 2 HD", src: 'bn-2_6_dark.png', start: true, max: true},
        {title: "Vikom", src: 'vikom_6_dark.png', start: true, max: true, ino: true},
        {title: "Kontakt TV", src: 'kontakt-tv_6_dark.png', start: true, max: true},
        {title: "Shoptel", src: 'shoptel_6_dark.png', start: true, max: true},
        // {title: "Slobomir", src: 'slobomir_6_dark.png', start: true, max: true},
        // {title: "Bel TV", src: 'bel-tv_6_dark.png', start: true, max: true},
        {title: "HRT 1 HD", src: 'hrt-1_6_dark.png', start: true, max: true},
        {title: "HRT 2 HD", src: 'hrt-2_6_dark.png', start: true, max: true},
        {title: "HRT 3 HD", src: 'hrt-3_6_dark.png', start: true, max: true},
        {title: "RTL HD", src: 'rtl-hd_6_dark.png', start: true, max: true},
        {title: "RTL 2 HD", src: 'rtl-2_6_dark.png', start: true, max: true},
        {title: "RTL Living", src: 'rtl-living_6_dark.png', start: true, max: true},
        {title: "RTS 1 HD", src: 'rts-1_6_dark.png', start: true, max: true},
        {title: "RTS 2 HD", src: 'rts-2_6_dark.png', start: true, max: true},
        {title: "RTS 3 HD", src: 'rts-3_6_dark.png', start: true, max: true},
        {title: "RTS Svet HD", src: 'rts-svet_6_dark.png', start: true, max: true},
        {title: "Pink HD", src: 'pink_6_dark.png', start: true, max: true},
        {title: "Prva HD", src: 'prvatv_6_dark.png', start: true, max: true},
        {title: "Hype", src: 'hype_6_dark.png', max: true},
        {title: "Hype 2", src: 'hype-2_6_dark.png', max: true},
        {title: "K1 HD", src: 'k1_6_dark.png', start: true, max: true},
        {title: "Red TV", src: 'red-tv_6_dark.png', start: true, max: true, ino: true},
        {title: "Happy TV", src: 'happy-tv_6_dark.png', start: true, max: true},
        {title: "B92", src: 'b92_6_dark.png', start: true, max: true},
        {title: "Euronews HD", src: 'euronews-serbia_6_dark.png', start: true, max: true},
        {title: "Tanjug HD", src: 'tanjug_6_dark.png', start: true, max: true},
        {title: "RTV 1", src: 'rtv-1_6_dark.png', start: true, max: true},
        {title: "KCN 1", src: 'kcn-1_6_dark.png', start: true, max: true},
        {title: "Bloomberg Adria HD", src: 'bloomerg-adria-hd_6_dark.png', start: true, max: true},
        {title: "Аdria", src: 'adria-tv_6_dark.png', start: true, max: true},
        {title: "Nikšić TV HD", src: 'niksic-tv_6_dark.png', start: true, max: true},
        {title: "TV Hram", src: 'tv-hram_6_dark.png', start: true, max: true},
        {title: "HRT int. HD", src: 'hrt-int-hd_6_dark.png', start: true, max: true},
        {title: "Arena Sport Premium 1 HD", src: 'arena-sport-1-premium-hd_6_dark.png', max: true, plus: true, extra: true},
        {title: "Arena Sport Premium 2 HD", src: 'arena-sport-2-premium-hd_6_dark.png', max: true, plus: true, extra: true},
        {title: "Arena Sport Premium 3 HD", src: 'arena-sport-3-premium-hd_6_dark.png', max: true, plus: true, extra: true},
        {title: "Arena Sport 1 HD", src: 'arena-sport-1_6_dark.png', start: true, max: true},
        {title: "Arena Sport 2 HD", src: 'arena-sport-2_6_dark.png', start: true, max: true},
        {title: "Arena Sport 3 HD", src: 'arena-sport-3_6_dark.png', start: true, max: true},
        {title: "Arena Sport 4 HD", src: 'arena-sport-4_6_dark.png', start: true, max: true},
        {title: "Arena Sport 5 HD", src: 'arena-sport-5_6_dark.png', start: true, max: true},
        {title: "Arena Sport 6 HD", src: 'arena-sport-6_6_dark.png', start: true, max: true},
        {title: "Eurosport HD", src: 'eurosport_6_dark.png', max: true},
        {title: "Eurosport 2 HD", src: 'eurosport-2_6_dark.png', max: true},
        {title: "Arena Fight HD", src: 'arena-fight_6_dark.png', start: true, max: true},
        {title: "Fight Network HD", src: 'fight-network_6_dark.png', start: true, max: true},
        {title: "Arena Esports HD", src: 'arena-esports_6_dark.png', start: true, max: true},
        {title: "Kuvo TV HD", src: 'kuvo-tv_6_dark.png', start: false, max: true},
        {title: "SOS", src: 'sos_6_dark.png', start: true, max: true},
        {title: "Extreme Sports HD", src: 'extreme-sports-channel_6_dark.png', start: true, max: true},
        {title: "Premier League TV HD", src: 'premier-league-tv-hd_6_dark.png', start: true, max: true},
        {title: "Zvezda TV HD", src: 'zvezda-tv_6_dark.png', start: true, max: true},
        {title: "Partizan TV HD", src: 'partizan-tv_6_dark.png', start: true, max: true},
        {title: "LFCTV HD", src: 'liverpool-fc-tv_6_dark.png', start: true, max: true},
        {title: "Fightbox HD", src: 'fightbox_6_dark.png', plus: true, extra: true},
        {title: "Gametoon HD", src: 'gametoon_6_dark.png', plus: true, extra: true},
        {title: "Fast&FunBox HD", src: 'fastfunbox_6_dark.png', plus: true, extra: true},
        {title: "Nickelodeon HD", src: 'nickelodeon_6_dark.png', max: true},
        {title: "Nick Jr.", src: 'nick-junior_6_dark.png', max: true},
        {title: "Nick Toons", src: 'nicktoons_6_dark.png', max: true},
        {title: "Minimax", src: 'minimax_6_dark.png', max: true},
        {title: "JimJam", src: 'jimjam_6_dark.png', max: true},
        {title: "Tropik TV HD", src: 'tropik-tv_6_dark.png', start: true, max: true},
        {title: "RTL Kockica", src: 'rtl-kockica_6_dark.png', start: true, max: true},
        {title: "Boomerang", src: 'boomerang_6_dark.png', max: true},
        {title: "Dexy TV HD", src: 'dexy-tv-hd_6_dark.png', start: true, max: true},
        {title: "Kazbuka HD", src: 'kazbuka-tv-hd_6_dark.png', start: true, max: true},
        {title: "Pink Kids", src: 'pink-kids_6_dark.png', start: true, max: true, ino: true},
        {title: "Super RTL", src: 'super-rtl_6_dark.png', start: true, max: true},
        {title: "Cartoon Nerwork", src: 'cartoon-network_6_dark.png', plus: true, extra: true},
        {title: "Pink Super Kids", src: 'pink-super-kids_6_dark.png', plus: true, extra: true, ino: true},
        {title: "Baby TV", src: 'baby-tv_6_dark.png', plus: true, extra: true},
        {title: "Disney", src: 'disney_6_dark.png', plus: true, extra: true},
        {title: "Hayatovci", src: 'hayat_hayatovci.png', start: true, max: true},
        {title: "Superstar 2", src: 'superstar-2_6_dark.png', start: true, max: true},
        {title: "Superstar 3", src: 'superstar-3.png', start: true, max: true},
        {title: "CineStar", src: 'cinestar_6_dark.png', max: true},
        {title: "CineStar Action", src: 'cinestar-tv-action_6_dark.png', max: true},
        {title: "CineStar Fantasy", src: 'cinestar-tv-fantasy_6_dark.png', max: true},
        {title: "CineStar Comedy", src: 'cinestar-tv-comedy_6_dark.png', max: true},
        // {title: "HBO", src: 'hbo_6_dark.png', start: true, max: true},
        // {title: "HBO 2", src: 'hbo2_6_dark.png', start: true, max: true},
        // {title: "HBO 3", src: 'hbo3_6_dark.png', start: true, max: true},
        // {title: "Cinemax", src: 'cinemax_6_dark.png', start: true, max: true},
        // {title: "Cinemax 2", src: 'cinemax2_6_dark.png', start: true, max: true},
        {title: "TV1000", src: 'tv1000_6_dark.png', start: true, max: true},
        {title: "AMC", src: 'amc-balkan_6_dark.png', max: true},
        {title: "Pickbox", src: 'pickbox_6_dark.png', max: true},
        {title: "Epic Drama HD", src: 'epic-drama-hd_6_dark.png', start: true, max: true},
        {title: "Filmbox Extra HD", src: 'filmbox-extra-hd_6_dark.png', max: true},
        {title: "Dizi", src: 'dizi-hd_6_dark.png', start: true, max: true},
        {title: "Euro Cinema 1", src: 'eurocinema-1_6_dark.png', start: true, max: true},
        {title: "Euro Cinema 2", src: 'eurocinema-2_6_dark.png', start: true, max: true},
        {title: "Euro Cinema 3", src: 'eurocinema-3_6_dark.png', start: true, max: true},
        {title: "Euro Cinema 4", src: 'eurocinema-4_6_dark.png', start: true, max: true},
        {title: "Klasik TV", src: 'klasik-tv_6_dark.png', start:true, max: true},
        {title: "Pink Film", src: 'pink-film_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Action", src: 'pink-action_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Premium HD", src: 'pink-premium-hd_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Thriller", src: 'pink-thriller_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Comedy", src: 'pink-comedy_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Romance", src: 'pink-romance_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Movies", src: 'pink-movies_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Crime&Mystery", src: 'pink-crimemistery_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Cinema", src: 'pink-world-cinema_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Family", src: 'pink-family_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Horror", src: 'pink-horror_6_dark.png', start: true, max: true, ino: true},
        {title: "Prva Max", src: 'prva-max_6_dark.png', start: true, max: true},
        {title: "AXN", src: 'axn_6_dark.png', max: true},
        {title: "AXN Spin", src: 'axn-spin_6_dark.png', extra: true, plus: true},
        {title: "Prva Kick", src: 'prva-kick_6_dark.png', start: true, max: true},
        {title: "Prva Life", src: 'prva-life_6_dark.png', start: true, max: true},
        {title: "Prva Files", src: 'prva-files_6_dark.png', start: true, max: true},
        {title: "Pink Serije", src: 'pink-serije_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Soap", src: 'pink-soap_6_dark.png', start: true, max: true},
        {title: "Pink World", src: 'pink-world_6_dark.png', start: true, max: true},
        {title: "Star Movies", src: 'fox-movies_6_dark.png', plus: true, extra: true},
        {title: "Filmbox Stars", src: 'filmbox-stars_6_dark.png', plus: true, extra: true},
        {title: "Filmbox Premium HD", src: 'filmbox-premium-hd_6_dark.png', plus: true, extra: true},
        {title: "Filmbox Arthouse", src: 'filmbox-arthouse_6_dark.png', plus: true, extra: true},
        {title: "Pink Classic", src: 'pink-classic_6_dark.png', plus: true, extra: true, ino: true},
        {title: "Pink Sci-Fi", src: 'pink-sci-fifantasy_6_dark.png', plus: true, extra: true, ino: true},
        {title: "Pink Western", src: 'pink-western_6_dark.png', plus: true, extra: true, ino: true},
        {title: "Discovery", src: 'discovery_6_dark.png', max: true},
        {title: "History", src: 'history_6_dark.png', max: true},
        {title: "Viasat Explore", src: 'viasat-explore_6_dark.png', start: true, max: true},
        {title: "Animal Planet HD", src: 'animal-planet_6_dark.png', max: true},
        {title: "Travel HD", src: 'travel-channel_6_dark.png', max: true},
        {title: "HGTV HD", src: 'hgtv_6_dark.png', max: true},
        {title: "DOX TV", src: 'dox-tv_6_dark.png', max: true},
        {title: "Pink Pedia", src: 'pink-pedia_6_dark.png', start: true, max: true, ino: true},
        {title: "Da Vinci", src: 'davinci-learning_6_dark.png', max: true},
        {title: "TV DR HD", src: 'tv-dr-hd_6_dark.png', start: true, max: true},
        {title: "Dr.Fit HD", src: 'dr-fit_6_dark.png', max: true},
        {title: "Planet Earth HD", src: 'planet-earth_6_dark.png', max: true},
        {title: "Crime & Investigation", src: 'crime-investigation-network_6_dark.png', max: true},
        {title: "History 2 HD", src: 'history-2-hd_6_dark.png', plus: true, extra: true},
        {title: "Viasat History", src: 'viasat-history_6_dark.png', plus: true, extra: true},
        {title: "Viasat Nature", src: 'viasat-nature_6_dark.png', plus: true, extra: true},
        {title: "Nat Geo Wild HD", src: 'nat-geo-wild-hd_6_dark.png', plus: true, extra: true},
        {title: "Docubox HD", src: 'docubox_6_dark.png', plus: true, extra: true},
        // {title: "DTX", src: 'dtx-hd_6_dark.png', plus: true},
        {title: "RT Doc HD", src: 'rt-doc_6_dark.png', plus: true, extra: true},
        {title: "RTG TV", src: 'rtg_6_dark.png', plus: true, extra: true},
        // {title: "Discovery Science HD", src: 'discovery-science_6_dark.png', plus: true},
        {title: "Investigation Discovery HD", src: 'investigation-discovery_6_dark.png', max: true},
        {title: "TLC", src: 'tlc-balkans_6_dark.png', max: true},
        // {title: "CBS Reality", src: 'cbs-reality_6_dark.png', max: true},
        {title: "Fashion TV HD", src: 'fashion-tv-hd_6_dark.png', max: true},
        {title: "Pink Kuvar", src: 'pink-kuvar_6_dark.png', start: true, max: true, ino: true},
        {title: "RTL DE", src: 'rtl-de_6_dark.png', start: true, max: true},
        {title: "Zdravlje TV", src: 'zdravlje-tv_6_dark.png', start: true, max: true},
        {title: "Woman HD", src: 'woman_6_dark.png', max: true},
        {title: "Moj Astrolog", src: 'moj-astrolog-hd_6_dark.png', start: true, max: true},
        {title: "Food Network HD", src: 'food-network-hd_6_dark.png', max: true, plus: true, extra: true},
        {title: "Fashionbox HD", src: 'fashion-box-hd_6_dark.png', plus: true, extra: true},
        {title: "Pink Style", src: 'pink-style_6_dark.png', plus: true, extra: true, ino: true},
        {title: "My Zen TV HD", src: 'my-zen-tv_6_dark.png', plus: true, extra: true},
        {title: "Toxic TV HD", src: 'toxic-tv-hd_6_dark.png', start: true, max: true},
        {title: "Pink Music", src: 'pink-music_6_dark.png', start: true, max: true, ino: true},
        {title: "DM SAT", src: 'dm-sat_6_dark.png', start: true, max: true},
        {title: "Pink Folk 2", src: 'pink-folk-2_6_dark.png', start: true, max: true, ino: true},
        {title: "KCN Music 2", src: 'kcn-2-music_6_dark.png', start: true, max: true},
        {title: "Hayat Love Box", src: 'hayat_love_box.png', max: true},
        {title: "KCN Svet 3", src: 'kcn-3-svet_6_dark.png', start: true, max: true},
        {title: "Duga TV", src: 'duga-plus_6_dark.png', start: true, max: true, ino: true},
        {title: "Pink Hits", src: 'pink-hits-1_6_dark.png', start: true, max: true, ino: true},
        {title: "MTV 80s", src: 'mtv-80s_6_dark.png', plus: true, extra: true},
        {title: "MTV 90s", src: 'mtv-90s_6_dark.png', plus: true, extra: true},
        {title: "MTV Live HD", src: 'mtv-live_6_dark.png', plus: true, extra: true},
        {title: "MTV Club", src: 'club-mtv_6_dark.png', plus: true, extra: true},
        {title: "360 TuneBox HD", src: '360-tunebox_6_dark.png', plus: true, extra: true},
        {title: "Toxic Folk HD", src: 'toxic-folk-hd_6_dark.png', plus: true, extra: true},
        {title: "Bravo Music", src: 'bravo-music_6_dark.png', plus: true, extra: true},
        {title: "Mezzo HD", src: 'mezzo_6_dark.png', plus: true, extra: true},
        {title: "CNN", src: 'cnn-international_6_dark.png', max: true},
        {title: "Russia Today HD", src: 'rusia-today-hd_6_dark.png', start: true, max: true},
        {title: "RTR Planeta", src: 'rtr-planeta_6_dark.png', start: true, max: true},
        {title: "DW", src: 'dw_6_dark.png', start: true, max: true},
        {title: "Happy Reality 1", src: 'happy-reality-1_6_dark.png', start: true, max: true},
        {title: "Happy Reality 2", src: 'happy-reality-2_6_dark.png', start: true, max: true},
        {title: "RTRS Radio", src: 'rtrs-radio_6_dark.png', radio: true},
        {title: "Bobar Radio", src: 'bobar-radio_6_dark.png', radio: true},
        {title: "UNO Radio", src: 'uno-radio_6_dark.png', radio: true},
        {title: "BIG 1", src: 'big-1_6_dark.png', radio: true},
        {title: "BIG 2", src: 'big-2_6_dark.png', radio: true},
        {title: "BIG 3", src: 'big-3_6_dark.png', radio: true},
        {title: "BIG 4", src: 'big-4_6_dark.png', radio: true},
        {title: "Nes Radio", src: 'nes-radio-dark.png', radio: true},
        {title: "Radio Doboj", src: 'radio-doboj_dark.png', radio: true},
        {title: "Kontakt", src: 'kontak-t-radio_dark.png', radio: true},
        {title: "Pop FM", src: 'pop-fm_dark.png', radio: true},
        {title: "Plavi Radio", src: 'plavi-radio_dark.png', radio: true},
        {title: "BLC Radio", src: 'blc-radio_dark.png', radio: true},
        {title: "A-radio", src: 'a-radio_dark.png', radio: true},
        {title: "Kozara Radio", src: 'kozara-radio_dark.png', radio: true},
        {title: "Magic Radio", src: 'magic-radio_dark.png', radio: true},
        {title: "K3 Radio", src: 'k3-radio_dark.png', radio: true},
        {title: "Daš Radio", src: 'das-radio_dark.png', radio: true},
        {title: "Daš Extra", src: 'das-extra-radio_dark.png', radio: true},
        {title: "SIM Radio", src: 'sim-radio.png', radio: true},
        {title: "Naxi Radio", src: 'naxi-radio.png', radio: true},
        {title: "Naxi Cafe Radio", src: 'naxi-cafe-radio.png', radio: true},
        {title: "Naxi Ex-Yu Radio", src: 'naxi-exyu-radio.png', radio: true},
        {title: "Naxi Kids Radio", src: 'naxi-kids-radio.png', radio: true},
        {title: "Naxi Rock Radio", src: 'naxi-rock-radio.png', radio: true},
        {title: "Radio Beograd 1", src: 'radio-beograd_6_dark.png', radio: true},
        {title: "Radio Beograd 2", src: 'radio-beograd2_6_dark.png', radio: true},
        {title: "Radio Beograd 3", src: 'radio-beograd3_6_dark.png', radio: true},
        {title: "Radio Beograd 202", src: 'radio-beograd202_6_dark.png', radio: true},
        {title: "RTS Rokenroler", src: 'radio-rts-rokenrol_6_dark.png', radio: true},
        {title: "RTS Džuboks", src: 'radio-rts-dzuboks_6_dark.png', radio: true},
        {title: "RTS Pletenica", src: 'radio-rts-pletenica_6_dark.png', radio: true},
        {title: "RTS Vrteška", src: 'radio-rts-vrteska_6_dark.png', radio: true},
        {title: "RTS Džezer", src: 'rts-dzezer-radio.png', radio: true}
      ]
   );

    let activeFilter = ref(null);
    let clickedButton = ref(null);

    function applyFilter(filterValue){
      if(filterValue === 'all'){
        this.activeFilter = null;
        this.clickedButton = 'all';
      }else{
        this.activeFilter = filterValue;
        this.clickedButton = filterValue;
      }
    };

   onBeforeMount(() => {
      window.scrollTo(0, 0)
    });
   
   return {cards, activeFilter, clickedButton, applyFilter}
    
  },


  computed:{

    filteredItems() {
      if(this.activeFilter == null) {
        return this.cards;
      }else if(this.activeFilter == 'max'){
        return this.cards.filter(item => item.max == true);
      }else if(this.activeFilter == 'start'){
        return this.cards.filter(item => item.start == true);
      }else if(this.activeFilter == 'plus'){
        return this.cards.filter(item => item.plus == true);
      }else if(this.activeFilter == 'extra'){
        return this.cards.filter(item => item.extra == true);
      }else if(this.activeFilter == 'ino'){
        return this.cards.filter(item => item.ino == true);
      }else if(this.activeFilter == 'radio'){
        return this.cards.filter(item => item.radio == true);
      }  
    },

    biggerScreen() {
      if(this.$vuetify.display.width >= 960){
        return true;
      }else{
        return false;
      }
    },
  }
  
}
</script>

<style>
  .channels{
      position: relative;
      width: 100%;
      height: 380px;
      display: flex;
      /* align-items: center; */
      justify-content: center;
      padding-top: 40px;
    }
  .channels::before{
    content: "";
    background-image: url('@/assets/images/banner.jpg');
    background-size: cover;
    background-position: 50% 20%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
    /* filter: grayscale(80%); */
  }

  .channels h1{
    font-family: 'Pero-Bold';
  }
  .channels p{
    font-family: 'Roboto-Light';
  }

  .channels-list{
    background: rgb(0, 0, 0, 0.84);
    padding-top: 50px;
    padding-bottom: 50px; 
    font-family: 'Pero-Bold';
  }
  .filter-buttons{
    font-family: 'Pero-Bold';
    background: rgb(0, 0, 0, 0.84);
    padding-bottom: 11px;
  }

  .subheading{
    padding-bottom: 20px;
  }

</style>