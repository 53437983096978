<template>
  <div v-if="biggerScreenCarousel" class="hero">
    <v-carousel
      show-arrows="hover"
      :height="carouselHeight"
      cycle
      color="white"
      hide-delimiter-background
      interval="5500"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        cover
        @click="handleItemClick(i)"
      ></v-carousel-item>
    </v-carousel>
  </div>
  <div class="hero" v-else>
    <v-carousel
      :show-arrows="false"
      :height="carouselHeight"
      cycle
      color="white"
      hide-delimiter-background
      interval="5500"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(item,i) in mobile_items"
        :key="i"
        :src="item.src"
        cover
        @click="handleItemClick(i)"
      ></v-carousel-item>
    </v-carousel>
  </div>
  <div class="about-sbon" :style="this.$vuetify.display.width >= 960 ? 'height:600px' : 'height:710px'">
    <v-container>
      <v-row class="about-text" justify="center">
        <v-col class="text-left" sm="12" xs="12" md="7" lg="5" xl="6">
          <h1 v-if="biggerScreen" class="text-white font-bold text-6xl pt-6">PLATI MOVE</h1>
          <h1 v-else class="text-white font-bold text-4xl">PLATI MOVE</h1>
          <h1 v-if="biggerScreen" class="text-white font-bold leading-none pt-1 text-6xl">ONLINE</h1>
          <h1 v-else class="text-white font-bold leading-none pt-1 text-4xl">ONLINE</h1>
          <p class="text-white mt-4 text-base">Plati MOVE online bez komplikacija! Sada možeš platiti MOVE tako što registruješ platnu karticu bilo koje banke za plaćanje i uživaš bez prekida u najboljim sadržajima.</p> <br>
          <p class="text-white pt-2">Ukoliko nemaš platnu karticu ili ti se ne dijele podaci kartice na Internetu, na raspolaganju ti je i Sbon vaučer koji možeš kupiti na bilo kojoj trafici označenoj Sbon zastavicom.</p>
          <!-- <p class="text-white pb-3">Pogledaj uputstvo za:</p> -->
          <div :style="this.$vuetify.display.width >= 960 ? 'display: flex; margin-top: 12px;' : 'display: flex; justify-content: center; align-items: center; margin-top:19px;'">
            <v-btn color="white" class="mr-2" @click="this.sbonDialog = true;">
              <span v-if="biggerScreen" class="font-semibold">Detaljnije</span>
              <span v-else class="font-semibold">Detaljnije</span>
            </v-btn>
            <a href="https://sbon.ba" target="_blank">
              <v-btn color="white" class="mr-2">
                <span v-if="biggerScreen" class="font-semibold">Posjeti SBON.BA</span>
                <span v-else class="font-semibold">Posjeti SBON.BA</span>
              </v-btn>
            </a>
          </div>
        </v-col>
        <v-col v-if="this.$vuetify.display.width >= 960" class="text-right sbon-image" sm="12" xs="12" md="5" lg="7" xl="6">
          <img src="../assets/images/sbon-logo.png" alt="logo">
        </v-col>
        <v-col v-else cols="12" class="text-center mt-3" style="display: flex; justify-content: center; align-items: center; margin-bottom: 10px">
          <img src="../assets/images/sbon-logo.png" alt="logo" height="150" width="150">
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div class="about" :style="this.$vuetify.display.width >= 960 ? 'height:600px' : 'height:520px'">
    <v-container>
      <v-row class="about-text" justify="center">
        <v-col class="text-left" sm="12" xs="12" md="7" lg="5" xl="6">
          <h1 v-if="biggerScreen" class="text-white font-bold text-6xl pt-6">MOVE</h1>
          <h1 v-else class="text-white font-bold text-4xl">MOVE</h1>
          <h1 v-if="biggerScreen" class="text-white font-bold leading-none pt-1 text-6xl">TV PO TVOM</h1>
          <h1 v-else class="text-white font-bold leading-none pt-1 text-4xl">TV PO TVOM</h1>
          <p class="text-white mt-4 text-base">Odaberi MOVE i uživaj u omiljenim TV sadržajima u bilo kom trenutku. Prati najnovije informacije, gledaj mečeve sa najvećih sportskih događaja i zabavi se uz filmske i serijske naslove u ponudi naših videoteka.</p>
          <p class="text-white pt-2">Za više informacija o aktivaciji usluge pozovi besplatan broj <span class="font-bold">0800 30 630</span>.</p>
          <p class="text-white pb-3">Pogledaj uputstvo za:</p>
          <v-btn color="white"  class="mr-2" @click="openStbPdf">
            <span v-if="biggerScreen" class="font-semibold">STB uređaj</span>
            <span v-else class="font-semibold">STB</span>
          </v-btn>
          <v-btn color="white" class="mr-2" @click="openTVPdf">
            <span v-if="biggerScreen" class="font-semibold">TV uređaj</span>
            <span v-else class="font-semibold">TV</span>
          </v-btn>
          <v-btn color="white" @click="openMobilePdf">
            <span v-if="biggerScreen" class="font-semibold">Mobilni uređaj</span>
            <span v-else class="font-semibold">Mobilni</span>
          </v-btn>
        </v-col>
        <v-col v-if="this.$vuetify.display.width >= 960" class="text-right" sm="12" xs="12" md="5" lg="7" xl="6">
          <img src="../assets/images/white-logo.svg" alt="logo">
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div class="benefits">
    <v-container>
      <v-row>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-card  variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-television-play</v-icon><span class="text-white font-bold benefit-title">Više od 190 TV i radio kanala</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Uživaj u super ponudi informativnih, sportskih, filmskih, serijskih, dječijih TV kanala i zabavi se uz omiljene radio stanice.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-television-play</v-icon><span class="text-white font-bold text-xl benefit-title">Više od 190 TV i radio kanala</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Uživaj u super ponudi informativnih, sportskih, filmskih, serijskih, dječijih TV kanala i zabavi se uz omiljene radio stanice.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card  variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-devices</v-icon><span class="text-white font-bold benefit-title">Više uređaja istovremeno</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Izaberi MOVE i gledaj omiljeni TV sadržaj na tri uređaja istovremeno (1 STB uređaj i dvije aplikacije).</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-devices</v-icon><span class="text-white font-bold text-xl benefit-title">Više uređaja istovremeno</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Izaberi MOVE i gledaj omiljeni TV sadržaj na tri uređaja istovremeno (1 STB uređaj i dvije aplikacije).</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-video</v-icon><span class="text-white font-bold benefit-title">Videoteke</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Zabavi se uz najveće naslove domaće i svjetske produkcije putem šest videoteka (Superstar, Apollon, Nick+, Pickbox NOW, FilmBox i AXN Now).</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-video</v-icon><span class="text-white font-bold text-xl benefit-title">Videoteke</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Zabavi se uz najveće naslove domaće i svjetske produkcije putem šest videoteka</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card  variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-rotate-left</v-icon><span class="text-white font-bold benefit-title">Gledaj ispočetka</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Omogućava ti da TV sadržaj vratiš na početak emitovanja.</p></v-card-text>
          </v-card>
        </v-col> 
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-rotate-left</v-icon><span class="text-white font-bold text-xl benefit-title">Gledaj ispočetka</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Omogućava ti da TV sadržaj vratiš na početak emitovanja.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-repeat</v-icon><span class="text-white font-bold benefit-title">Gledaj propušteno</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Naknadno vrati sadržaj do 72h unazad i pogledaj ono što si propustio u redovnom terminu emitovanja.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-repeat</v-icon><span class="text-white font-bold text-xl benefit-title">Gledaj propušteno</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Naknadno vrati sadržaj do 72h unazad i pogledaj ono što si propustio u redovnom terminu emitovanja.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-motion-pause-outline</v-icon><span class="text-white font-bold benefit-title">Pauziraj TV</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Pauziraj program i nastavi da ga gledaš kasnije kada za to budeš imao vremena.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-motion-pause-outline</v-icon><span class="text-white font-bold text-xl benefit-title">Pauziraj TV</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Pauziraj program i nastavi da ga gledaš kasnije kada za to budeš imao vremena.</p></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="sbonDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      
    >
    <v-card style="background: #e9e9e9;">
      <v-toolbar
          dark
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        >
          <v-toolbar-title class="text-white" :style="biggerScreen ? 'font-size: 20px' : 'font-size:16px'">Načini plaćanja</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              variant="text"
              @click="sbonDialog = false"
            >
              <v-icon size="large" color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="mt-8">
          <v-row justify="center">
            <v-col xs="12" sm="12" md="10" lg="10" xl="8">
            <v-expansion-panels elevation="4">
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Plaćanje bankovnom karticom
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Da bi izvršio plaćanje bankovnom karticom potrebno je da posjeduješ Masterkard ili VISA bankovnu karticu i da je registruješ za plaćanje na sajtu https://movetv.ba/profil. Proces registracije se pokreće iz korisničkog panela iz dijela  „MOJA KARTICA“ klikom da polje „DODAJ KARTICU“ i realizuje u nekoliko jednostavnih i ustaljenih koraka kod svake vrste kartičnih plaćanja na internetu. <br> <br>
                  •	Na prvom koraku se otvara obrazac gdje unosiš podatke i sva polja su obavezna i polje „DALJE“ će biti aktivno samo kada su svi podaci ispunjeni. <br>
                  •	Potom se se otvara forma gdje unosiš podatke o kartici, čekiranjem kvadrata „Potvrđujem procesiranje podataka“ i klikom na „Potvrdi“ pokreće se procesiranje kartice, provjera podataka i registracija kartice za plaćanje. <br>
                  •	Nakon unešenih podataka vrši se verifikacija dostavljanih podataka sa matičnom bankom koja može da se razlikuje zavisno od vrste integrisanih sistema zaštite i ovaj korak se može razlikovati od vrste banke izdavaoca kartice. <br>
                  •	Nakon uspješne verifikacije kartice otvoriće se stranica sa porukom o uspješnom dodavanj kartice i klikom na dugme „MOJ NALOG“ korisnik se vraća svoj panel gdje nastavlja proces kupovine. <br> <br>
                  Aktiviranje dodatnih paketa ili produžavanje perioda osnovnog paketa  se radi na stranici „PAKETI I UREĐAJI“ ,  pokreće se kliknom na okvir „PRETPLATI SE“ željenog paketa, nakon čega se pojavljuje poruka čijom potvrdom klikom na „PRETPLATI SE“ verifikuješ odabir i  
                  pokrećeš proceduru plaćanja sa svoje platne kartice koju si registrovao, a za iznos odabranog paketa ili pretplatnog perioda. Pregled transakcija je dostupan na stranici „MOJ NALOG“ u dijelu  „MOVETV NALOG“  klikom na dugme „PRIKAŽI TRANSAKCIJE“.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Plaćanje sa računa MOVETV naloga
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Da bi izvršio plaćanje dodatnog paketa ili videoteka kao i plaćanje produženja perioda osnovnog paketa potrebno je račun svog move naloga dopuniti minimalno do iznosa dovoljnog za plaćanje željenog paketa, a to možeš učiniti na dva načina: <br> <br>
                  •	Dopunom move računa iznosom sa Sbon-a. <br>
                  •	Dopunom move računa uplatom na POS terminalu. <br> <br>
                  <span class="font-semibold underline pb-4">Dopuna move računa iznosom sa Sbon-a</span> <br> <br>
                  Postupak dodavanje sredstava iznosom sa Sbon vaučera pokrećeš iz korisničkog panela iz dijela  „MOVETV NALOG“ klikom da polje „DOPUNI RAČUN“, nakon čega se otvara se šablon za unos koda gdje je potrebno da uneseš 16-cifreni kod Sbon vaučera. 
                  Po unosu validnog koda otvara se klizač za odabir iznosa i okvir za unos iznosa kojim možeš odrediti koliku sumu želiš da uplati za kupovinu MOVE TV paketa. Ukoliko ne iskoristiš cjelokupan iznos sa Sbon vaučera za dopunjavanje move TV naloga ostatak od iznosa ti ostaje na raspolaganju da iskoristiš po drugom osnovu koje Sbon omogućava. <br>
                  <span class="underline"> Po završetku postupka uplaćeni iznos ti se prikazuje na kartici „Stanje računa“ gdje pratiš svoj saldo i raspoloživa sredstava za plaćanje move paketa. </span> <br> <br>
                  <span class="font-semibold underline pb-4">Dopuna move računa uplatom na POS terminalu</span> <br> <br>
                  Da bi dopunio svoj move račun uplatom na POS terminalu potrebno je da znaš ID broj svog move naloga, a koji se nalazi u korisničkom panelu u dijelu „MOVETV NALOG“ ispod imena i prezimena koje si unio prilikom registracije naloga. 
                  Operater kod kojeg dopunjavaš move nalog će unijeti ID broj na osnovu kojeg će uplaćena sredstva dopuniti tvoj move račun. <br>
                  <span class="underline">Po završetku postupka uplaćeni iznos ti se prikazuje na kartici „Stanje računa“ gdje pratiš svoj saldo i raspoloživa sredstava za plaćanje move paketa.</span> <br> <br>
                  Aktiviranje dodatnih paketa ili produžavanje perioda osnovnog paketa  se radi na stranici „PAKETI I UREĐAJI“ ,  pokreće se kliknom na okvir „PRETPLATI SE“ željenog paketa, nakon čega se pojavljuje poruka čijom potvrdom klikom na „PRETPLATI SE“ verifikuješ odabir i  pokrećeš proceduru plaćanja sa svog računa koji si prethodno dopunio, a za iznos odabranog paketa ili pretplatnog perioda.
                  Pregled transakcija je dostupan na stranici „MOJ NALOG“ u dijelu  „MOVETV NALOG“  klikom na dugme „PRIKAŽI TRANSAKCIJE“.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Šta je Sbon i gdje ga mogu iskoristiti?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Sbon je vrijednosni bon/vaučer na kojem se nalazi jedinstveni kod sa kojim možeš jednostavno i sigurno izvršiti plaćanje različitih usluga. <br> <br>
                  <span class="font-semibold pb-4">Putem Sbon-a možeš:</span> <br>
                  - dopuniti prepaid račun u m:tel mreži; <br>
                  - platiti Supernova MOVE TV uslugu; <br>
                  - učestvovati u igrama na sreću kod priređivača koji koriste Sbon kao sredstvo uplate. <br>
                </v-expansion-panel-text>
              </v-expansion-panel>     
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Gdje mogu kupiti Sbon?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Vaučer je moguće kupiti na svim mjestima koja su označena Sbon logom ili zastavicom, odnosno na više od 2.400 lokacija širom Bosne i Hercegovine. Sbon potražite na kioscima, u prodavnicama ili na benzinskim stanicama. <br> <br>
                  Lokaciju u svom gradu potražite na <a class="underline text-blue-600" target="_blank" href="https://www.google.com/maps/d/u/0/viewer?mid=12Ii16kCE1aNS2sp3rQB0DG-3GEDkuKE&ll=43.96985652556863%2C17.695238800000027&z=6">linku.</a>
                </v-expansion-panel-text>
              </v-expansion-panel>     
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Dodatne informacije o Sbon-u
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Kupovina Sbon-a je moguća u iznosu od 1,00 KM do 250,00 KM. <br> <br>
                  Sbon se prilikom plaćanja određene usluge kod partnera može iskoristiti u cjelosti za jednu kupovinu ili djelimično i to u roku od jedne godine od dana kupovine Sbon-a. <br> <br>
                  Zamjena Sbon-a za novčana sredstva nije moguća. <br> <br>
                  Za sve informacije i pitanja u vezi Sbon-a i funkcionisanja Sbon sistema možete se obratiti besplatnim pozivom na broj 0800 50 000.
                </v-expansion-panel-text>
              </v-expansion-panel>     
              </v-expansion-panels>
          </v-col>
          </v-row>
        </v-container>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, onBeforeMount} from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup(){

    const router = useRouter();
    const items = ref([
      {
        src: require('../assets/images/move-slider12.jpg'),
      },
      {
        src: require('../assets/images/move-banner11.jpg'),
      },
      {
        src: require('../assets/images/move-banner8.jpg'),
      },
      {
        src: require('../assets/images/move-banner9.jpg'),
      },
      {
        src: require('../assets/images/move-banner6.jpg'),
      }
    ]);

    const mobile_items = ref([
      {
        src: require('../assets/images/mobile-slider12.jpg'),
      },  
      {
        src: require('../assets/images/mobile-slider11.jpg'),
      },
      {
        src: require('../assets/images/mobile-slider8.jpg'),
      },
      {
        src: require('../assets/images/mobile-slider9.jpg'),
      },
      {
        src: require('../assets/images/mobile-slider6.jpg'),
      }
    ]);

    const sbonDialog = ref(false);

    function handleItemClick(index){
      if(index == 0){
        window.open('https://www.supernovabih.ba/wp-content/uploads/2024/10/MOVE-TV-MOB-NET-uputstvo.pdf', '_blank');
      }
      else if(index == 1){
        router.push({ name: 'ProfileView'});
      }else if(index == 2){
        router.push({ name: 'PackagesView'});
      }else if(index == 3){
        router.push({ name: 'VideosView'});
      }
    }

    onBeforeMount(() => {
      window.scrollTo(0, 0);
    });
  
    return {items, mobile_items, sbonDialog, handleItemClick}
  },

  methods:{
    openTVPdf(){
      window.open('https://acrobat.adobe.com/id/urn:aaid:sc:EU:3dbf623b-ba2a-4ad1-a94d-7efe54c3db72');
    },
    openMobilePdf(){
      window.open('https://acrobat.adobe.com/id/urn:aaid:sc:eu:65481b4c-47da-4df9-87aa-361f481cdba3');
    },
    openStbPdf(){
      window.open('https://acrobat.adobe.com/id/urn:aaid:sc:EU:e08e5b75-0e47-4837-b02d-67e7b5c3bb81');
    }
  },

  computed:{
    biggerScreen () {
      if(this.$vuetify.display.width >= 620){
        return true;
      }else{
        return false;
      }
    },

    biggerScreenCarousel () {
      if(this.$vuetify.display.width >= 960){
        return true;
      }else{
        return false;
      }
    },

    carouselHeight (){
      if(this.$vuetify.display.width > 1300 && this.$vuetify.display.width < 1600){
        return 690;
      }else if(this.$vuetify.display.width > 1600){
        return 903;
      }else if(this.$vuetify.display.width < 960 && this.$vuetify.display.width > 400){
        return 600;
      }else{
        return 450;
      }
    }
  }
}
</script>

<style>
  
  .hero-image{
    position: relative;
    height: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .hero-image::before{
    content: "";
    background-image: url('@/assets/images/move-banner1.jpg');
    background-size: cover;
    background-position: center ;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
  }

  .about-sbon{
    background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
    /* background: rgb(177, 13, 40); */
    padding-top: 10px;
    border-bottom: #9c0601 8px dotted;
  }

  .sbon-image{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about{
    /* height: 700px; */
    background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
    padding-top: 10px;
  }

  .about-text{
    padding-top: 60px;
    font-family: 'Pero-Bold';
  }

  .about-text p{
    font-family: 'Roboto-Light';
    font-size: 18px;
  }

  @media only screen and (max-width: 960px){
    .about-text {
      padding-top: 18px;
      padding-bottom: 60px;
    }
  }

  .benefits{
    max-height: 1500px;
    padding-top: 80px;
    padding-bottom: 80px;
    /* background-color: #343434; */
    background: rgb(0, 0, 0, 0.84);
  }

  .benefits p{
    font-family: 'Roboto-Light';
  }

  @media only screen and (max-width: 960px){
    .benefits{
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .benefit-title{
    font-family: 'Pero-Bold';
    font-size: 32px;
  }

  .v-carousel .v-btn {
    color: black;
    background-color: white;
  }

</style>
